import { Col, Container, DangerouslySetInnerHtml, Row } from 'gatsby-theme-husky/src/layout';
import React, { FC, useContext, useEffect, useState } from 'react';
import Banner from 'components/Banner';
import { CountryContext } from '../../context/CountryContext/CountryContext';
import { ICountryContext } from '../../context/CountryContext/model';
import { ILdsBody } from './models';

const LdsComponent: FC<ILdsBody> = ({ pageName, url }) => {
  const { allLds, state } = useContext<ICountryContext>(CountryContext);
  const [currentCountry, setCurrentCountry] = useState('');
  useEffect(() => {
    setCurrentCountry(pageName);
  }, []);
  const res = allLds.find((i) => i.pageName === currentCountry && i.codeCountry === state);

  return (
    <div className="lds-page__wrapper">
      {res && res.banner
        ? res.banner.map(({ properties: { title, ...restBannerProperties } }) => (
            <Banner
              key={title}
              url={url}
              title={title}
              {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
            />
          ))
        : null}
      {res && res.body ? (
        <div className="lds-content" data-version={res.versionId}>
          <Container>
            <Row>
              <Col sm="12" md="12" lg="12" xl="12">
                <DangerouslySetInnerHtml
                  html={res.body.replace(new RegExp(/(<p>&nbsp;<\/p>)/gi), '')}
                />
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  );
};

export default LdsComponent;
